@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,500;1,500&display=swap);
.app_app__cewju {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.header_header__v9FKw {
  height: 20px;
  background-color: rgb(222, 148, 139);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 20px 40px;
}

.header_text__logo__343U4 {
  font-style: italic;
  z-index: 300;
  font-weight: 500;
  font-size: 20px;
  color: white;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

.header_text__logo__343U4:hover {
  font-weight: 600;
}

.header_menu__4iihD {
  margin: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  min-width: 120px;
  font-weight: 400;
}

.header_menu__4iihD li a {
  text-decoration: none;
  color: white;
}

.header_menu__4iihD li {
  transition: all 0.2s ease-in-out;
}

.header_menu__4iihD li:hover {
  cursor: pointer;
  background-color: rgb(31, 104, 119);
  color: white;
}

@media (max-width: 576px) {
  .header_header__v9FKw {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    height: 100%;
  }

  .header_menu__4iihD {
    min-width: 80px;
  }
}

.main_main__wrapper__37HQv {
  min-height: calc(100vh - 40px);
  width: 100%;
  background-color: darkpink;
  display: flex;
  box-sizing: border-box;
}

.main_left__block__3waz_ {
  display: flex;
  flex-direction: column;
  border-right: 1px solid black;
  width: 50%;
}

.main_left__block__upper__block__3cHmA {
  box-sizing: border-box;
  padding: 20px;
  height: 50%;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.main_emojis__block__39ysA {
  font-size: 26px;
  display: flex;
  justify-content: flex-start;
}

.main_introduction__block__XuLFA {
  position: relative;
}

.main_emojis__block__39ysA > *:not(last-child) {
  margin-right: 10px;
}

.main_highlighted__link__13WT8 {
  background-color: rgb(31, 104, 119);
  color: white;
  text-decoration: none;
  transition: -webkit-text-decoration 0.3s ease-in-out;
  transition: text-decoration 0.3s ease-in-out;
  transition: text-decoration 0.3s ease-in-out, -webkit-text-decoration 0.3s ease-in-out;
}

.main_highlighted__link__13WT8:hover {
  text-decoration: underline;
  background-color: rgb(35, 92, 103);
}

.main_right__block__3cio8 {
  box-sizing: border-box;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.main_left__block__bottom__block__33zuu {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  text-align: center;
}

.main_contacts__block__Uhb6b {
  padding: 20px;
  /* height: calc(50% - 10px); */
  display: flex;
  width: 100%;
  justify-content: center;
}

.main_links__3LV-3 {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.main_links__3LV-3 > * {
  transition: all 0.2s ease-in-out;
}

.main_links__3LV-3 > *:hover {
  background-color: rgb(31, 104, 119);

  color: white;
}

.main_photo__wrapper__1Jhla {
  max-width: 330px;
}

.main_photo__wrapper__1Jhla img {
  width: 100%;
}

@media (max-width: 768px) {
  .main_main__wrapper__37HQv {
    flex-direction: column;
    flex-wrap: wrap-reverse;
  }

  .main_left__block__3waz_ {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid black;
  }

  .main_right__block__3cio8 {
    width: 100%;
  }

  .main_links__3LV-3 {
    flex-direction: column;
    align-items: center;
  }
}

.projects_projects__wrapper__4GcYx {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px 20px;
  gap: 10px 20px;
}

.projects_projects__wrapper__4GcYx > * {
  margin: 0 20px 20px 0;
}

@media (max-width: 768px) {
  .projects_projects__wrapper__4GcYx {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .projects_projects__wrapper__4GcYx > * {
    margin: 0 10px 10px 0;
  }
}

.projectCard_card__wrapper__uHiEK {
  height: 270px;
  box-sizing: border-box;
  padding: 20px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.4s ease-in-out;
  text-align: center;
}

.projectCard_card__wrapper__uHiEK:hover {
  transform: scale(1.02);
  padding: 19px;
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.projectCard_image__wrapper__3Cbg0 {
  width: 130px;
  display: flex;
  align-items: center;
}

.projectCard_image__wrapper__3Cbg0 img {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .projectCard_card__wrapper__uHiEK {
    border-bottom: 1px solid black;
    flex-basis: 100%;
    border-right: none;
    border-left: none;
    border-top: 1px solid black;
  }

  .projectCard_card__wrapper__uHiEK:hover {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
}

/* normalize css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono', monospace;
  box-sizing: border-box;
}

ul {
  margin: 0;
}

ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

