/* normalize css */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,500;1,500&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono', monospace;
  box-sizing: border-box;
}

ul {
  margin: 0;
}

ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}
