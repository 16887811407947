.main__wrapper {
  min-height: calc(100vh - 40px);
  width: 100%;
  background-color: darkpink;
  display: flex;
  box-sizing: border-box;
}

.left__block {
  display: flex;
  flex-direction: column;
  border-right: 1px solid black;
  width: 50%;
}

.left__block__upper__block {
  box-sizing: border-box;
  padding: 20px;
  height: 50%;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.emojis__block {
  font-size: 26px;
  display: flex;
  justify-content: flex-start;
}

.introduction__block {
  position: relative;
}

.emojis__block > *:not(last-child) {
  margin-right: 10px;
}

.highlighted__link {
  background-color: rgb(31, 104, 119);
  color: white;
  text-decoration: none;
  transition: text-decoration 0.3s ease-in-out;
}

.highlighted__link:hover {
  text-decoration: underline;
  background-color: rgb(35, 92, 103);
}

.right__block {
  box-sizing: border-box;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.left__block__bottom__block {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  text-align: center;
}

.contacts__block {
  padding: 20px;
  /* height: calc(50% - 10px); */
  display: flex;
  width: 100%;
  justify-content: center;
}

.links {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.links > * {
  transition: all 0.2s ease-in-out;
}

.links > *:hover {
  background-color: rgb(31, 104, 119);

  color: white;
}

.photo__wrapper {
  max-width: 330px;
}

.photo__wrapper img {
  width: 100%;
}

@media (max-width: 768px) {
  .main__wrapper {
    flex-direction: column;
    flex-wrap: wrap-reverse;
  }

  .left__block {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid black;
  }

  .right__block {
    width: 100%;
  }

  .links {
    flex-direction: column;
    align-items: center;
  }
}
