.card__wrapper {
  height: 270px;
  box-sizing: border-box;
  padding: 20px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.4s ease-in-out;
  text-align: center;
}

.card__wrapper:hover {
  transform: scale(1.02);
  padding: 19px;
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.image__wrapper {
  width: 130px;
  display: flex;
  align-items: center;
}

.image__wrapper img {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .card__wrapper {
    border-bottom: 1px solid black;
    flex-basis: 100%;
    border-right: none;
    border-left: none;
    border-top: 1px solid black;
  }

  .card__wrapper:hover {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
}
